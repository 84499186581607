import React from "react"
import Layout from "../components/Layout/Layout"
import ServiceList from "../components/ServiceList/ServiceList"
import LatestContent from "../components/LatestContent/LatestContent"
import { useStaticQuery, graphql } from "gatsby"
import { MdMailOutline } from 'react-icons/md';
import "./index.scss"
import { Container } from "react-bootstrap"

const IndexPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
          }
        }
      }
    `
  )

  const schemaOrganization = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "@id": "https://www.guidingdigital.com/",
    "name": "Guiding Digital",
    "legalName" : "Guiding Digital LLC",
    "description" : site.siteMetadata.description,
    "url": "https://www.guidingdigital.com",
    "logo": "https://www.guidingdigital.com/images/logo.png",
    "image" : "https://www.guidingdigital.com/images/logo.png",
    "telephone" : "(479) 709-2520",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "4797092520",
      "contactType": "sales"
    },
    "address" : {
      "@type" : "PostalAddress",
      "addressLocality" : "Springdale",
      "addressCountry" : "US",
      "addressRegion" : "Arkansas",
      "postOfficeBoxNumber" : "8053",
      "postalCode" : "72766"
    },
    "sameAs": [
      "https://www.youtube.com/guidingdigital",
      "https://twitter.com/GuidingDigital",
      "https://www.linkedin.com/company/guidingdigital/",
      "https://www.facebook.com/GuidingDigital/"
    ]
  }
  return (
  <Layout
    title="Guiding Digital: Web Development, SEO, and Developer Support"
      description="A digital agency located in Northwest Arkansas specializing in web development and hosting, SEO, and online content and training for developers."
      schemaMarkup={schemaOrganization}>
    <Container className="homeHero">
      <div className="innerContent">
        <h1><span className="heading1">Guiding your</span><br /><span className="heading2">digital journey</span></h1>

        Guiding Digital exists to support passionate developers and organizations as they 
        strive for digital excellency!

        <br />
        <br />
 
        <form id="contactForm" action="/contact/" method="get" className="needs-validation">
          <MdMailOutline className="emailIcon" />
          <input className="form-control form-control-lg d-block d-sm-inline" type="email" 
            name="email" id="email" placeholder="Enter email" 
            required aria-label="Email" />
          <input type="submit" value="Contact Us" className="btn btn-primary" />
        </form>
      </div>
      
    </Container>

    <Container className="section-container servicesSection">
      <ServiceList />

      <LatestContent title="Latest Content" />
    </Container>
  </Layout>
  )
}

export default IndexPage