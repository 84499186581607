import React from "react"
import {Row, Col, Card } from 'react-bootstrap'
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import PostIcon from '../../images/postIcon.svg'
import VideoIcon from '../../images/videoIcon.svg'
import './LatestContent.scss'

const LatestContent = ({title}) => {
    return (
    <div class="latestContent">
      <div className="sectionHeader">
        <h2>Checkout our latest content</h2>
        <p>
          Follow our blog and watch our videos to see what we’ve been working 
          on lately.
        </p>

        <button type="button" className="btn btn-primary">
          <Link to="/blog/">
            View All Posts&nbsp;&nbsp;&nbsp;
            <img src={PostIcon} alt="View All Posts" width="24" height="22" />
          </Link>
        </button>

        <button type="button" className="btn btn-secondary">
          <a href="https://www.youtube.com/guidingdigital" target="_blank" rel="noreferrer">
            Watch Videos&nbsp;&nbsp;&nbsp;
            <img src={VideoIcon} alt="Watch Videos" width="18" height="22" />
          </a>
        </button>
      </div>
      <Row>
        <Col md={6} lg={4} className="pb-3 pr-0 pl-0 pr-sm-3 pl-sm-3">
          <Card className="text-center">
          <Link to="/blog/core-web-vitals/">
            <StaticImage
              src="../../images/blog/core-web-vitals.png"
              alt="Core Web Vitals"
              className="card-img-top" />
            </Link>
            <Card.Body>
              <Card.Text>
                <div className="articleBadge">Article</div>
                <h3><Link to="/blog/core-web-vitals/">Core Web Vitals Update</Link></h3>
                <div className="abstract">
                  Learn more about Google's core web vitals update and how you can prepare.
                </div>
                <div>
                  <StaticImage
                    src="../../images/blog/philip-sparks.png"
                    alt="Philip Sparks"
                    className="profileImage"
                    width={50}
                    quality={100}
                    placeholder="none" />
                    <div>
                      <span className="author">Philip Sparks</span><br />
                      <span className="publishedDate">May 13, 2021</span> 
                    </div>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4} className="pb-3 pr-0 pl-0 pr-sm-3 pl-sm-3">
          <Card className="text-center">
          <a href="https://youtu.be/Dwi99jtl3Fs" target="_blank" rel="noreferrer">
            <StaticImage
              src="../../images/blog/upgrading-gatsby-to-ga4-video-thumbnail.png"
              alt="Upgrading a Gatsby Site to Google Analytics 4"
              className="card-img-top" />
            </a>
            <Card.Body>
              <Card.Text>
                <div className="videoBadge">Video</div>
                <h3><a href="https://youtu.be/Dwi99jtl3Fs" target="_blank" rel="noreferrer">Upgrading a Gatsby Site to Google Analytics 4</a></h3>
                <div className="abstract">
                  Google Analytics 4 is now the default version of Google Analytics. 
                  Learn how to upgrade your Gatsby website from Universal Analytics to GA4.
                </div>
                <div>
                  <StaticImage
                    src="../../images/blog/philip-sparks.png"
                    alt="Philip Sparks"
                    className="profileImage"
                    width={50}
                    quality={100}
                    placeholder="blurred" />
                    <div>
                      <span className="author">Philip Sparks</span><br />
                      <span className="publishedDate">March 23, 2021</span> 
                    </div>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4} className="pb-3 pr-0 pl-0 pr-sm-3 pl-sm-3">
          <Card className="text-center">
            <a href="https://youtu.be/wX9euOw4mGY" target="_blank" rel="noreferrer">
              <StaticImage
                src="../../images/blog/gatsby-and-ga4-using-gtm-video-thumbnail.png"
                alt="Gatsby &amp; Google Analytics 4 Using GTM"
                className="card-img-top"
                placeholder="blurred" />
            </a>
            <Card.Body>
              <Card.Text>
                <div className="videoBadge">Video</div>
                <h3><a href="https://youtu.be/wX9euOw4mGY" target="_blank" rel="noreferrer">How to Add Google Analytics 4 to a Gatsby Site Using Google Tag Manager</a></h3>
                <div className="abstract">
                  Google Analytics 4 is now the default version of Google Analytics. 
                  Learn how to add Google Analytics 4 to your Gatsby JS website using Google Tag Manager.
                </div>
                <div>
                  <StaticImage
                    src="../../images/blog/philip-sparks.png"
                    alt="Philip Sparks"
                    className="profileImage"
                    width={50}
                    quality={100}
                    placeholder="blurred" />
                    <div>
                      <span className="author">Philip Sparks</span><br />
                      <span className="publishedDate">February 22, 2021</span> 
                    </div>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
    )
}

export default LatestContent