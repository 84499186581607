import React from "react"
import { Link } from "gatsby"
import seoIcon from './seo-icon.svg'
import webDevIcon from './web-development-icon.svg'
import developerSupportIcon from './developer-support-icon.svg'
import './ServiceList.scss'

const ServiceList = () => {
  return (
    <>
      <div className="serviceHeader">
        <h2>We are here to help</h2>
        <p>
          Let us help grow your digital presence and ROI with the following 
          services.
        </p>
      </div>
    
      <div className="serviceItem service1 row">
        <div className="col iconCol">
          <img src={seoIcon} alt="Search Engine Optimization" width="150" height="150" 
            className="serviceIcon d-none d-md-block float-right" />
        </div>
        <div className="col-md-9 contentCol">
          <h3>Search Engine Optimization</h3>          
          <p>
            Already have a website and want to make sure it is optimized for 
            search? Contact us for a SEO audit and optimization plan.
          </p>
          <Link to="/services/seo/">Learn More About SEO Services &gt;</Link>
        </div>
      </div>

      <div className="serviceItem service2 row">
        <div className="col-md-9 contentCol">
          <h3>Website Development &amp; Hosting</h3>
          <p>
            We work with you to create a website that is optimized from the 
            start to meet your business goals.
          </p>
        </div>
        <div className="col iconCol">
          <img src={webDevIcon} alt="Website Design and Development" width="150" height="150"
            className="serviceIcon d-none d-md-block" />
        </div>
      </div>

      <div className="serviceItem service3 row">
        <div className="col iconCol">
            <img src={developerSupportIcon} alt="Developer Support" width="150" height="150"
              className="serviceIcon d-none d-md-block float-right" />
          </div>
          <div className="col-md-9 contentCol">
          <h3>Developer Support</h3>
          <p>
            We're here to support developers through online training and content.
          </p>
        </div>
      </div>
    </>
  )
}

export default ServiceList